export default function chatTemplate() {
  return {
    isShow: true,
    contents: [
      {
        modify: false,
        value: '请输入常用语句'
      },
      {
        modify: false,
        value: '请输入常用语句'
      },
      {
        modify: false,
        value: '请输入常用语句'
      }
    ]
  }
}
