<template>
	<el-dialog :title="title" :visible.sync="dialogVisible" width="912px" height="512px">
		<!--医生输入诊费-->
		<div class="main">

			<!--    <el-input
          placeholder="疾病名称" 
          v-model="price" 
      >
	   
      </el-input> -->


			<div class="head_search">
				<el-input placeholder="请输入内容" v-model="inputValue" class="input-with-select" size="large"
					prefix-icon="el-icon-search">

					<el-button style="margin: 0 0px; width: 100%; " slot="append" @click="search_name" class="input-btn"
						size="mini">搜索</el-button>

				</el-input>
				<el-divider direction="vertical"></el-divider>
			</div>


		</div>




		<button @click="goSet(2)">使用</button><br /> <br />


		<div style="width: 80%; height: 300px;  ">

			<div v-for="(item, i) in list" :key="i" :span="41" style="width: 100%!important;"><label>

					<input name="Fruit" type="checkbox" v-model="contents" :value="item.name" />{{item.name}} </label>

			</div>

		</div>





	</el-dialog>
</template>

<script>
	import axios from "axios";
	export default {
		name: 'SearchDisease',
		components: {},
		data() {
			return {
				inputValue: '',
				name: '',
				list: [],
				contents: [],
				dialogVisible: false,
				title: '去支付11',
				price: '',
				coupons: [],
				currentIndex: 0,
				total: 50,
				currentWay: 'wx'
			}
		},
		computed: {
			couponNum() {
				return this.coupons[this.currentIndex].num
			}
		},
		methods: {
			search_name() {
				alert(this.inputValue)	
				axios.post(this.apiUrl + '/api/Chatroom/getDiseaseList?name='+this.inputValue)
					.then((response) => {
				
						this.list = response.data.data;
				
					})
					.catch(function(error) {
						console.log(error);
					});  
			},
			goSet() {
				console.log(this.contents);
				var str = ";"
				for (var j = 0, len = this.contents.length; j < len; j++) {
					str += this.contents[j] + "  ";
				}
				this.$parent.textarea = str;
				this.dialogVisible = false;

			},
			open() {
				this.title = '疾病搜索2'
				this.dialogVisible = true

				axios.post(this.apiUrl + '/api/Chatroom/getDiseaseList')
					.then((response) => {

						this.list = response.data.data;

					})
					.catch(function(error) {
						console.log(error);
					});

					
			},


		}
	}
</script>

<style scoped lang="scss">
	.head_search {
		position: relative;

		.input-with-select {
			width: 780px;

			/*height: 48px;*/
			.input-btn {}
		}

		.el-divider {
			position: absolute;
			left: 114px;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
			height: 30px;
			background: #f5f5f5;
		}
	}

	.main {
		.title {
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			line-height: 20px;
			padding-bottom: 14px;
		}

		.el-input {
			margin-bottom: 90px;
		}

		.price {
			font-size: 18px;
			color: #333;
			padding-left: 20px;

			span {
				font-size: 40px;
			}
		}

		.tip {
			font-size: 12px;
			color: #FF1515;
			line-height: 17px;
			padding: 18px 0 59px;
		}

		.pay_text {
			color: #999;
			font-size: 18px;
			margin-bottom: 30px;

			&>span {
				margin-right: 48px;
			}

			.red {
				color: #FF2828;

				span {
					font-size: 40px;
				}
			}
		}

		.coupon_wrap {
			margin-bottom: 17px;
			width: 100%;
			height: 80px;

			.coupons {
				display: flex;
				height: 56px;

				.coupon {
					flex-shrink: 0;
					width: 150px;
					height: 56px;
					margin-right: 24px;
					background-color: $green;
					font-size: 12px;
					color: #fff;
					text-align: center;
					line-height: 18px;
					padding-top: 4px;

					.coupon_num {
						font-size: 14px;
						padding-right: 10px;
					}

					.red {
						background-color: #FF2828;
						display: inline-block;
						position: relative;
						bottom: -2px;
						padding: 0 4px;
					}
				}

				.choosed {
					background-color: #A5E3E7;
				}
			}

		}

		.payWays {
			margin-bottom: 90px;
			display: flex;

			.wayItem {
				height: 55px;
				margin-right: 40px;
				position: relative;

				i {
					position: absolute;
					bottom: 10px;
					right: 0;
					font-size: 21px;
					color: #FF2828;
				}
			}
		}
	}

	/deep/ .el-dialog__header {
		background-color: $green;
		height: 33px;
		padding: 0 0 0 27px;

		.el-dialog__title {
			line-height: 33px;
			font-size: 12px;
			color: #fff;
		}

		.el-dialog__headerbtn {
			top: 8px;

			.el-dialog__close {
				color: #fff;
			}
		}
	}

	/deep/ .el-dialog__body {
		padding: 14px 20px;

		.el-input__prefix {
			// left: 20px!important;
			//line-height: 48px;
			font-size: 12px;
			color: #333;
		}

		.el-input--medium .el-input__inner {
			text-indent: 18px;
			height: 48px;
		}
	}

	/deep/ .el-dialog__footer {
		text-align: center;
		padding-bottom: 50px;

		.el-button--primary {
			width: 190px;
			height: 32px;
			background-color: $green;
			border-color: $green;
		}
	}

	/deep/ .el-scrollbar__wrap {
		overflow-y: hidden !important;
	}
</style>
