<template>
  <el-dialog
      title="病历"
      :visible.sync="dialogVisible"
      width="612px"
  >
    <!--医生输入诊费-->
    <div class="main">
      <div class="record">
        <el-image :src="require('./img/record.png')" />
        王医生病历
      </div>
      <div class="record">
        <el-image :src="require('./img/record.png')" />
        王医生病历
      </div>
      <div class="record">
        <el-image :src="require('./img/record.png')" />
        王医生病历
      </div>
    </div>

    <!--底部操作按钮-->
<!--    <span slot="footer" class="dialog-footer">-->
<!--      <el-button type="primary">发送</el-button>-->
<!--    </span>-->
  </el-dialog>
</template>

<script>
  export default {
    name: 'Record',
    data() {
      return {
        dialogVisible: true
      }
    },
    methods: {
      open(){
        this.dialogVisible = true
      }
    }
  }
</script>

<style scoped lang="scss">
  .main{
    display: flex;
    .record{
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 17px;
      align-items: center;
      padding: 30px;
      .el-image{
        width: 30px;
      }
    }
  }
  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 6px 26px;
    min-height: 275px;
  }
</style>
