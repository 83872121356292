<template>




	<div class="chatRoot">

		<!-- <div class="news left" style="width:100%;text-align:left"> 
		  <img class="news_avatar" style="float:left" width="50" src="http://doctor.com/uploads/202112161639646467.jpg"> 
		  <div class="news_content" style="float:left; margin-left:10px;"> 
		   <span class="time">今天21:212121</span>     <br> 
		   <br>   <span id="2" class="text  span123  rightClick2"  onclick="aa()"       style="color:white;background:green;padding:10px 20px" > 1 </span>
		  </div>
	  </div>
	  
	  
	  <div class="news right" style="width:100%;text-align:right"> 
	  
	  <img class="news_avatar"style='float: right;mfargin-left: 10px;' src="http://doctor.com/uploads/202112151639574622.jpg" width="50">  
	  
		  <div class="news_content" style="float: right;color:red;">  
			  <span class="time">今天21:33</span>  <br>  <br> 
			  <span class="bubble" style="color:white;background:green;padding:10px 20px"> asdf </span>
		  </div>
	  </div>
	  -->

		<div class="case_jquery" style="
	  width:50%; 
	  height:75%;  
	  display:none;
	  position: fixed;
	  top:60px;
	  left: 25%;
	  z-index: 99999;
	  ">

			<div style="width: 100%;height: 4%;  padding:10px; background-color:#3BD1DC !important">

				<span style="color: white;">病例信息</span>

				<button style="float: right;" class="case_close">关闭</button>
				<button style="float: right;">打印</button>
			</div>

			<div style="width: 100%;height: 96%; padding:10px; background-color:antiquewhite"
				class="case_jquery_content">

			</div>



		</div>

		<ScrollNotice />
		<div class="chatWrap">




			<!--聊天主界面-->
			<div class="chatMain">
				<!--头部信息-->
				<div class="head">
					<div class="avatar">
						<img :src="this.apiUrl+info.pic" style="width: 100%; height: 100%">
					</div>
					<div class="info">
						<el-row>
							<el-col :span="24">
								<span class="nick">{{info.nickname}}</span>
								<span class="name">真实姓名: {{info.name}}</span>
								<span class="id">(ID: 23131)</span>
								<span class="lastTime">上次登录时间: {{info.last_login}}</span>
							</el-col>
							<el-col :span="8">
								<div class="rate_item">
									<span>技术满意度：0 </span>
									<el-progress :percentage="95" :format="format1"></el-progress>
								</div>
								<div class="rate_item">
									<span>文明满意度：0 </span>
									<el-progress :percentage="90" :format="format2"></el-progress>
								</div>
							</el-col>
							<el-col :span="16">
								<p class="info_detail">
									性别：{{info.sex === 1 ? '男' : '女'}}；年龄：{{info.age}}岁；民族：汉族；国籍：中国；专业：{{info.last_login}}；
								</p>
								<p class="info_detail">
									学历：{{info.last_login}}；籍贯：{{info.hometown_name}}；常住地址：{{info.address}}</p>
							</el-col>
							<el-col :span="24">
								<p>个人介绍：专业外科医生</p>
							</el-col>
						</el-row>
					</div>
					<div class="videoCall">
						<div class="video" style="text-align: center;">
							<i class="el-icon-video-camera" style="font-size: 32px;line-height: 96px"></i>
						</div>
						<div class="nav" style="width:360px;">

							<!--          <span class="nav_item">收藏</span>
              <el-divider direction="vertical" />
              <span class="nav_item">送礼物</span>
              <el-divider direction="vertical" />
              <span class="nav_item">评价</span>
              <el-divider direction="vertical" />
              <span class="nav_item">阻止</span>
              <el-divider direction="vertical" />
              <span class="nav_item">举报</span> -->


							<span class="nav_item" @click="" v-if="this.info.isCollected">已收藏</span>
							<span class="nav_item" @click="saveCollect2" v-else>收藏</span>
							<el-divider direction="vertical" />
							<span class="nav_item" @click="sendGifts">送礼物</span>
							<el-divider direction="vertical" />
							<span class="nav_item" @click="goEvaluate">去评价</span>
							<el-divider direction="vertical" />

							<span class="nav_item" @click="" v-if="this.info.isCollected">已阻止</span>
							<span class="nav_item" @click="setBlock" v-else>阻止</span>

							<el-divider direction="vertical" />
							<span class="nav_item" @click="goReport" v-if="this.info.isReport">已举报</span>
							<span class="nav_item" @click="goReport" v-else>举报</span>


						</div>
					</div>
				</div>
				<!--医生附带头部信息-->
				<!-- <div class="appendHead">
          <div class="appendAvatar">
            <el-image :src="require('../../assets/avatar/习春.jpg')" fit="cover" style="width: 100%; height: 100%"></el-image>
          </div>
          <div class="appendInfo">
            <div class="ellipsis">
              <span style="font-size: 16px;font-weight:600;color:#333;padding-right: 18px">王医生</span>
              性别：男；年龄：36岁；民族：汉族；国籍：中国；身高：175cm；体重：88kg；学历：本科；职业：农民；婚姻：未婚；籍贯：山东济南；病史陈诉者：个人；是否可靠：是。
            </div>
            <div>
              <span style="margin-right: 46px">家庭住址：山东省济南市槐荫区XXXXX</span>
              现住址：山东省济南市槐荫区XXXXX
            </div>
            <div>
              个人自白：腿部疼痛，时间有一年多了，吃了药都不见好转！
            </div>
          </div>
        </div> -->
				<!--警告-->
				<div class="warning">
					<i class="el-icon-info"></i>
					加强自我防范意识，警惕各种形式的奇方妙药
				</div>
				<!--消息展示框-->
				<div class="newsWrap">
					<!--循环生成消息列表，收到消息class为left，发送为right-->
					<el-scrollbar ref="scroll" style="height:100%">
						<div class="newsList" :class="{newEnd: isEnd}">

							<div id="app" v-html="html">
								　　<p v-text="msg">哈哈哈 arsenal</p>
							</div>

							<div v-for="(item,index) in all_data">
								<div v-if="item.local==0">
									<div class="news left" :id='item.ids'>
										<el-image class="news_avatar" :src="did_img" />
										<div class="news_content">
											<span class="time">今天21:33</span>

											<span v-if="item.show_type==1" class="text">{{item.mess}}</span>

											<span v-if="item.show_type==2" class="text"><img :src='item.mess' width=100>
											</span>

											<span v-if="item.show_type==3" class="text" @click="addCaseRecord"><img
													:src='item.mess' width=100> </span>

										</div>
									</div>
								</div>


								<div v-if="item.local==1">
									<div class="news right" :id='item.ids'>
										<el-image class="news_avatar" :src="id_img" />
										<div class="news_content">
											<span class="time">今天21:33</span>



											<span v-if="item.show_type==1" @contextmenu.prevent="delImg(item.ids)"
												class="text reght_css">{{item.mess}}</span>

											<span v-if="item.show_type==2" @contextmenu.prevent="delImg(item.ids)"
												class="text reght_css"><img :src='item.mess' width=100> </span>

											<span v-if="item.show_type==3" @contextmenu.prevent="delImg(item.ids)"
												class="text reght_css" @click="addCaseRecord"><img :src='item.mess'
													width=100> </span>





										</div>
									</div>
								</div>

							</div>
					<!-- <div class="news left" v-for="(item,index) in left_data">
					<el-image class="news_avatar" :src="id_img"/>
					<div class="news_content">
					  <span class="time">今天21:33</span>
					  <span class="text">{{item}}</span>
					</div>
				</div>
				
				<div class="news right" v-for="(item,index) in right_data">
					<el-image class="news_avatar" :src="did_img"/>
					<div class="news_content">
					  <span class="time">今天21:33</span>
					  <span class="text">{{item}}</span>
					</div>
				</div> -->


							<!-- 	<div class="news right " v-for="(item,index) in right_data">
					<el-image class="news_avatar" :src="id_img" />
					  <el-image class="news_avatar_append" :src="require('../../assets/avatar/习春.jpg')"/> 
					<div class="news_content">
					  <span class="time">今天21:33</span>
					  <span class="text" v-bind:id="'mess' + index"  >{{item}}</span>
					</div>
				</div>  
			  -->





						</div>
						<!--结束前选项-->
						<div class="beforeEnd" v-if="isEnd">
							<p>本次咨询即将结束，请选择</p>
							<div class="navBtn">
								<el-button>
									1.继续免费咨询1小时
								</el-button>
							</div>
							<div class="navBtn">
								<el-button>
									2.按首次咨询后7日内半价，收取咨询费
								</el-button>
							</div>
							<div class="navBtn">
								<el-button>
									3.按10小时内不再次收取咨询费继续咨询，最晚至每日晚7点
								</el-button>

							</div>
						</div>
					</el-scrollbar>
				</div>




				<!--快捷工具条-->
				<div class="send_tools_bar">



					<!--快捷输入工具按钮-->
					<div class="left_tools" style="
					width: 80%; 
					word-break: break-all; 
					word-wrap: break-word;
					line-height:50px; 
					overflow: hidden;  
					height: auto;
					display:block;
					padding: 10px 0;
					">



						<!--循环生成聊天模板-->

						<div v-if="spacks" style="
							line-height: 46px;
							text-align:center;
							margin-left: 50px;
							width: 40px;
							display:block;
							" class="left_tools_item" 
							@dblclick="showTemplate(chatTemplate.id)"
							@contextmenu.prevent="changeTemplate(chatTemplate.id)"
							v-for="(chatTemplate, index) in chatTemplates" @click.stop="">
							<!--   <i class="el-icon-chat-line-square" @click.stop="chooseTemplate(index)"></i> -->
							<img src="../../assets/images/q.png" width="22" alt="" @click.stop="chooseTemplate(index)">
							<br />
							<div style="font-size: 8px;line-height:0; width: 40px; margin-top: -10px; ">
								{{chatTemplate.name}}
							</div>

							<!-- <div style="font-size: 8px;line-height:0; width: 40px; " >模板{{index+1}}</div> -->
							<div class="chatTemplate" v-show="chatTemplateActive === index">
								<div class="template_item" v-for="content in chatTemplate.contents">
									<el-input :disabled="!content.modify" v-model="content.value" size="medium" />
									<div class="operateBtn">
										<el-button type="text" @click="content.modify = true">修改</el-button>
										<el-button type="text" @click="content.modify = false">保存</el-button>
										<el-button type="text" @click="textarea += content.value">使用</el-button>
									</div>
								</div>
							</div>
						</div>




						<!--添加文字模板-->
						<div class="left_tools_item" v-if="chatTemplates.length < 114   " 
						@click="addTemplate">
							<i class="el-icon-folder-add"></i>
						</div>
						<!-- 	添加文字模板-->
						
						
						
				
						 
						
						
						

						<!---->
						<div class="left_tools_item" 
						
						
							style="
								line-height: 46px;
								text-align:center;
								margin-left: 100px;
								margin-right: 30px;
								width: 40px;
								display:block;
								margin-left:1px;
								margin-top: 15px;
								"
							
								@dblclick="addImgPatient(chatTemplate.id)"
								@contextmenu.prevent="addImgPatient2(chatTemplate.id)"
								
								v-for="(chatTemplate, index) in chatImgTemplates" 
							>
							<img src="../../assets/images/room_imgs.jpg" width="22" alt="" @click.stop="chooseTemplate(index)">
							<br />
							 
							<div style="font-size: 8px;line-height:0; width: 40px; margin-top: -10px; ">
								{{chatTemplate.name}}
							</div>
							<!-- <el-upload class="avatar-uploader" action="#" :show-file-list="false" accept="image/*"
								:http-request="upload">

								<i class="el-icon-picture-outline-round" style="margin-right: 8px;"></i>

							</el-upload> -->
						</div>

		
					<!--添加聊天图片模板-->
					<div class="left_tools_item" v-if="chatTemplates.length < 114   " 
					@click="addImgTemplate">
						<i class="el-icon-folder-add"></i>
					</div>
					<!--添加聊天图片模板--> 
					
					
						<!--发送图片-->
						<div class="left_tools_item">
							<el-upload class="avatar-uploader" action="#" :show-file-list="false" accept="image/*"
								:http-request="upload">
								<img v-if="imageUrl" :src="imageUrl" class="avatar">

								<i class="el-icon-picture-outline"></i>
							</el-upload>
						</div>
						<!--发送视频通话-->
						<div class="left_tools_item" @click="goLive">
							<i class="el-icon-video-camera"></i>
						</div>
						<!--发送语音通话-->
						<div class="left_tools_item">
							<i class="el-icon-phone-outline"></i>
						</div>
						<!--资金提示图标-->
						<el-image class="icon8"
							src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge1d803706310cca72a64c39eace802f8f8bbd9a699dbf8cd797dfcdcb1d5cee6"
							@click="handlePay"></el-image>
					</div>



					<div class="right_tools">
						<!--快捷住院病例按钮-->
						<img src="../../assets/images/mzhuyuan.jpg" width="22" alt="" @click="addCaseRecord">
						<!--快捷门诊病例按钮-->
						<i style="margin-left: 30px;" class="el-icon-postcard" @click="addCaseRecord"></i>
					</div>
				</div>



				<div style="background-color:#D8D8D8" class="send_tools_bar">
					<div style="float: left; margin-left: 100px;;">
						<i class="el-icon-chat-line-square" @click="goInitialTemp(1)">初始模板 </i>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<i class="el-icon-chat-line-square" @click="goLableTemp(2)"> 标签模板 </i>

						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<i class="el-icon-chat-line-square" @click="goSearchDisease(2)"> 搜索疾病名称</i>

					</div>
				</div>


				<!--聊天输入框 readonly="true"  -->
				<div class="input_wrap">
					<el-input type="textarea" :rows="10" resize="vertical" :autofocus="true" v-model="textarea"
						@keyup.enter.native="sendMsgToServer()" :id="gomess">


					</el-input>


					<div class="send">
						<!-- 	 <i style="font-size:35px;" class="el-icon-postcard" @click="addCaseRecord"></i> -->
						<small class="csshi"> 按Enter键发送，按Ctrl+Enter键换行</small>
						<el-button type="primary" @click="sendMsgToServer">发送</el-button>
					</div>


				</div>
			</div>
			<div class="chatSide">


				<!--   <div class="side_top">
          <div class="pre_next">
            <el-button-group>
              <el-button type="primary" icon="el-icon-message">收件箱</el-button>
              <el-button type="primary" icon="el-icon-caret-top">上一位</el-button>
              <el-button type="primary" icon="el-icon-close">删&nbsp;&nbsp;&nbsp;&nbsp;除</el-button>
              <el-button type="primary" icon="el-icon-caret-bottom">下一位</el-button>
            </el-button-group>
          </div>
          <div class="timeCount">
            <el-button type="primary" style="font-size: 18px">{{duration}}</el-button>
          </div>
        </div>
		 -->


				<!--    <div class="side_middle">
          <el-button-group>
            <el-button type="primary">单选</el-button>
            <el-button type="primary">多选</el-button>
            <el-button type="primary">片选</el-button>
            <el-button type="primary">全选</el-button>
            <el-button type="primary">复制</el-button>
            <el-button type="primary">删除</el-button>
            <el-button type="primary">清空</el-button>
            <el-button type="primary">截屏</el-button>
          </el-button-group>
        </div> -->



			</div> 
		</div> 
		
		
 
		<ChatLive ref="ChatLive" :info="info" />

		<SearchDisease ref="searchDisease" /> 
 


		<WorldNameCaseInfo ref="WorldNameCaseInfo" @fatherMethod="getInfo" :info="info" />
		<ChatRoomPay @sendPayImg='getPayImg' ref="ChatRoomPay" />
		<Gifts ref="gifts" :info="info" />
		<Schedual ref="schedual" />

		<Evaluate ref="Evaluate" :info="info" />
		<ChatRoomAddCaseRecord ref="ChatRoomAddCaseRecord" :info="info" />
		<Report ref="Report" :info="info" />

		<Comment ref="Comment" :info="info" />
		<CaseInfo ref="CaseInfo" :info="info" />
		<CaseODInfo ref="CaseODInfo" :info="info" />

		<LabelInfo ref="LabelInfo" :info="info" />

		<InitialTemp ref="InitialTemp" :info="info" />


		<ChatRoomInitialTemp ref="ChatRoomInitialTemp" :info="info" />
		<ChatRoomLableTemp ref="ChatRoomLableTemp" :info="info" />
		<WorldCaseInfo ref="WorldCaseInfo" :info="info" />
		<ImgCaseInfo ref="ImgCaseInfo"  @goSendImgs="goSendImgs(e,file_id)" :info="info" />
		<ImgNameCaseInfo ref="ImgNameCaseInfo" @fatherMethod="getInfo" :info="info" />


		<!-- <Record ref="record"/> -->
	</div>
</template>
<script>
	import $ from "jquery"

	import axios from "axios";
	import io from 'socket.io-client';

	const socket = io('http://8.140.12.83:9515');
	import SockJS from 'sockjs-client';
	import Stomp from 'stompjs';
	import WorldNameCaseInfo from '@/components/AddWorldNameCaseInfo';

	import WorldCaseInfo from '@/components/AddWorldCaseInfo';
	import SearchDisease from '@/components/SearchDisease'

	import chatTemplate from '@/utils/chatTemplate'
	import ScrollNotice from "../../components/ScrollNotice"
	import ChatRoomPay from '@/components/ChatRoomPay'
	import Record from './Record'
	import {
		tio
	} from '../../utils/tiows'

	import CaseODInfo from '@/components/AddODCaseInfo'
	import CaseInfo from '@/components/AddCaseInfo'
	import LabelInfo from '@/components/AddLabelInfo'


	import ChatRoomAddCaseRecord from '@/components/ChatRoomAddCaseRecord'
	import ChatRoomInitialTemp from '@/components/ChatRoomInitialTemp'
	import ChatRoomLableTemp from '@/components/ChatRoomLableTemp'
	import ChatLive from '@/components/ChatLive'

	import Gifts from '@/components/Gifts'
	import Evaluate from '@/components/Evaluate'
	import Report from '@/components/Report'
	import Comment from '@/components/Comment'
	import Schedual from '@/components/Schedual'
	
	import ImgCaseInfo from '@/components/AddImgCaseInfo'; 
	import ImgNameCaseInfo from '@/components/AddImgNameCaseInfo';
	
	
	
	import {
		getMemo
	} from "../../api/DoctorApi";
	import {
		saveCollect
	} from "../../api/EvalApi";



	$(document).ready(function() {

		function aa() {
			app.fn();
		} 


		$("body").on("mousedown", ".rightClick", function(e) {

			app.fn();


			if (e.which == 3) {
				if (confirm("确定撤回此条信息?")) {
					var id_del = $(this).parent("div").parent("div").attr("id");
					alert(id_del)
					//点击确定后操作
					$(this).parent("div").parent("div").hide();
					var goMes = 33 + '_' + 2 + '_' + 222 + '_4';
					alert(goMes)
					socket.emit('hello', goMes);
				}
			}
			
			

		})

		$("body").on("click", ".case_close", function() {
			$('.case_jquery').hide();

		});

		$("body").on("click", ".case_span", function() {
			$('.case_jquery').show();
			var did = $(this).attr("did")
			var uid = $(this).attr("uid")
			var url = $(this).attr("url")

			$.ajax({
				url: url + "/api/user/getChatRoomAddCaseRecordInfo2?did=" + did + "&uid=" + uid,
				success: function(result) {

					$('.case_jquery_content').html(result);



				}
			});

		});

	});

	export default {
		el: "id2",
		name: 'Chat',
		components: {
			ChatLive,
			WorldNameCaseInfo,
			WorldCaseInfo,
			SearchDisease,
			ChatRoomAddCaseRecord,
			ChatRoomLableTemp,
			ChatRoomInitialTemp,
			ScrollNotice,
			ChatRoomPay,
			Record,
			Gifts,
			Schedual,
			Evaluate,
			Report,
			Comment,
			CaseInfo,
			CaseODInfo,
			LabelInfo, 
			ImgCaseInfo,
			ImgNameCaseInfo
		},
		data() {

			return {

				caseList: {},
				spacks: true,
				pay_img: '',
				format1: 23,
				imageUrl: '',
				msg: 'sd',
				did_img: '',
				//left_img:+this.apiUrl+this.id_img
				id_img: '',
				did: 0,
				info: {
					isBlock: '',
					isReport: '',
					isCollected: '',
				},
				mess: '',
				text: 0,
				all_data: [],
				left_data: [],
				right_data: [],
				stompClient: '',
				timer: '',
				textarea: '',
				chatTemplates: [

				],
				chatImgTemplates: [{
					isShow: true,
					contents: [{
							modify: false,
							value: '请输入常用语句'
						},
						{
							modify: false,
							value: '请输入常用语句'
						},
						{
							modify: false,
							value: '请输入常用语句'
						}
					]
				}],
				chatTemplateActive: null,
				duration: '00:00',
				isEnd: false,
				timeInterval: 0
			}
		},
	
		methods: { 
			
			go_live(){
				 let partNumbers = ''
				      
						const { href } = this.$router.resolve({
				     // path 路由器配置
				          path: `live`,
				         query: {
				         //  传参
				           'partNumbers': 12
				           }
				            })
				            //打开空白页面
				            window.open(href, '_blank') 
			},
			
			//发送图片 被子页面调用
			goSendImgs(d,file_id){ 
				
				const params = new URLSearchParams();
				params.append('lines', d); 
				params.append('file_id', file_id); 
				
				axios.post(this.apiUrl + '/api/User/getGoSendImg', params)
					.then((response) => { 
						for(let i in response.data.data){
							
							
							var g = new Date().getTime();
							var res = this.apiUrl+response.data.data[i];
							const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + res + '_2_' + g;
							//alert(goMes);
							socket.emit('hello', goMes);
							
							
							// this.fileList.push({
							// 	"url": this.apiUrl+response.data.data[i].content
							// })
						} 
					  
					})
					.catch(function(error) {
						console.log(error);
					}); 
					
					
			},
			//双击打开右键图片窗口
			addImgPatient(id) {  
				//alert(id)
				this.$refs.ImgCaseInfo.open(id)
			},
			
			//打开右键图片窗口
			addImgPatient2(id) {
				this.$refs.ImgNameCaseInfo.open(id)
			},
			
			
			//添加文字模板

			//修改文字模板 
			changeTemplate(id) {
				this.$refs.WorldNameCaseInfo.open(id)
			},
			//文字模板
			showTemplate(id) {
				this.$refs.WorldCaseInfo.open(id)

			},

			//邮件删除
			delImg(id) {

				var g = new Date().getTime();
				// alert(g)
				// alert(id)
				// alert(g - id)
				if ((g - id) > 180000) {
					this.$message.success('超过三分钟 不可撤回');
				} else {


					this.$confirm('确认撤回?', '', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'info'
					}).then(() => {
						$('#' + id).hide();


						const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + id + '_4';
						socket.emit('hello', goMes);

						// const  index = this.tasks.findIndex(funciton(ele){
						//           return ele.id == id
						//    });
						// //假设没有找到
						// if(index === -1){
						//      return alert('删除失败');
						// }
						// //删除元素
						// this.tasks.splice(index,1);

					}).catch(() => {});
				}
			},
			
			
			//门诊病例发送
			goOutpatientCases(img) {

				var g = new Date().getTime();
				const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + img + '_3_' + g;

				socket.emit('hello', goMes);

			},
			
			
			//疾病搜索
			goSearchDisease() {
				this.$refs.searchDisease.open(22);
			},



			//发送付款二维码 
			getPayImg(data) {

				var g = new Date().getTime();
				const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + data + '_2_' + g;

				socket.emit('hello', goMes);


			},


			//医生添加病例
			addCaseRecord() { 
				//this.$refs.ChatRoomAddCaseRecord.open(this.did);
			},

			setBlock() {
				// alert('11');
			},


			//直播间
			goLive() {
				this.$refs.ChatLive.open(this.did);
			},
			
			
			//标签模板
			goLableTemp() {
				this.$refs.ChatRoomLableTemp.open(22);
			},
			//初始化模板
			goInitialTemp() {
				console.log("---------------------------------");
				console.log(this.$refs);
				this.$refs.ChatRoomInitialTemp.open(22);
				//this.$refs.ChatRoomInitialTemp.open();  
			},
			goEvaluate() {
				this.$refs.Evaluate.open()
			},
			sendGifts() {
				this.$refs.gifts.open()
			},

			goComment() {
				this.$refs.Comment.open() 
			},

			goReport() {
				this.$refs.Report.open()
			},

			/**
			 * 收藏的操作
			 */
			saveCollect2() {
				const params = new URLSearchParams();


				params.append('uid', sessionStorage.getItem('uid'));
				params.append('bid', this.did);


				axios.post(this.apiUrl + '/api/User/addCollection', params)
					.then((response) => {

						this.$message.success('操作成功');
						this.info.isCollected = 1;


					})


					.catch(function(error) {
						console.log(error);
					});
			},

			enterSearch() {
				document.onkeydown = e => {
					//13表示回车键，baseURI是当前页面的地址，为了更严谨，也可以加别的，可以打印e看一下
					if (e.keyCode === 13 && e.target.baseURI.match(/freshmanage/)) {
						// alert(23);
						//回车后执行搜索方法
						this.sendMsgToServer()
					}
				}
			},
			// 上传图片
			upload(e) {
				//这是参数配置，可以忽略
				const tthash = window.btoa(
					window
					.btoa(encodeURI(location.hash.replace('#/', '') ?? ''))
					.split('')
					.reverse()
					.join('')
				)
				//使用FormData 的最大优点就是可以异步上传二进制文件。
				//而图片，文件之类的文件是二进制，接口接收参数也是接收的二进制
				//可以先通过new关键字创建一个空的 FormData 对象，然后使用 append() 方法向该对象里添加字段
				//（字段的值可以是一个 Blob 对象，File对象或者字符串，剩下其他类型的值都会被自动转换成字符串）。

				//也可以用一个已有的form元素来初始化FormData对象

				this.getBase64(e.file).then((res) => {

					var g = new Date().getTime();
					const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + res + '_2_' + g;
					//alert(goMes);
					socket.emit('hello', goMes);

					const formData = new FormData()


					//这里是把上传接口所有的参数都append到formData中
					formData.append('img', res)
					formData.append('uid', this.uid)

					const config = {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}

					//接口调用
					// axios.post(this.apiUrl+'/api/user/upImg', formData, config).then((res) => {
					//     this.imageUrl = this.apiUrl+res.data.data.pic;
					//     console.log(this.imageUrl);
					//     //this.from.logo = res.data.data.filename
					// })


				})

			},

			getBase64(file) {
				return new Promise(function(resolve, reject) {
					const reader = new FileReader()
					let imgResult = ''
					reader.readAsDataURL(file)
					reader.onload = function() {
						imgResult = reader.result
					}
					reader.onerror = function(error) {
						reject(error)
					}
					reader.onloadend = function() {
						resolve(imgResult)
					}
				})
			},


			sendMsgToServer() {


				var g = new Date().getTime();
				const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + this.textarea + '_1_' + g;

				//alert(goMes);
				socket.emit('hello', goMes);
				//socket.emit('hello',"1111111111111");
				// this.right_data.push('23_23_'+this.textarea) ;

				//给医生发送邮箱

				const params = new URLSearchParams();
				params.append('uid', sessionStorage.getItem('uid'));
				params.append('did', this.did);
				params.append('content', this.textarea);

				axios.post(this.apiUrl + '/api/user/setEmail', params)
					.then((response) => {
						console.log(response.data.data);

						//this.info = response.data.data;  

					})
					.catch(function(error) {
						console.log(error);
					});


			},


			format1() {
				return '4.5分'
			},
			format2() {
				return '4.9分'
			},

			// 点击资金按钮
			handlePay() {


				this.$refs.ChatRoomPay.open()

				$('textarea').removeAttr("readonly");

				this.spacks = true;


			},


			// 增加聊天图片模板
			addImgTemplate() {
				if (this.chatImgTemplates.length < 4) {
					this.chatImgTemplates.push(chatTemplate())
				}
			},



			// 增加聊天消息模板   文字模板
			addTemplate() {
				if (this.chatTemplates.length < 114) {
					const params = new URLSearchParams();
					params.append('uid', sessionStorage.getItem('uid'));
					axios.post(this.apiUrl + '/api/user/addCaseTemp', params)
						.then((response) => {
							this.chatTemplates = response.data.data;
						})
						.catch(function(error) {
							console.log(error);
						});
				}
			},


			// 点击消息模板
			chooseTemplate(index) {
				if (this.chatTemplateActive == index) {
					this.chatTemplateActive = null
				} else {
					this.chatTemplateActive = index
				}
			},
			openRecord() {
				this.$refs.record.open()
			},
			// 一位变两位
			toDuble(n) {
				if (n < 10) {
					n = '0' + n
				}
				return n
			},
		},
		//mounted() {


			// $(document).keydown(function (event) {
			//   if (event.keyCode == 13) {
			// 			//this.sendMsgToServer()
			//       const goMes= sessionStorage.getItem('uid')+'_'+this.did+'_'+this.textarea+'_1';;

			//       socket.emit('hello',goMes);
			//       //socket.emit('hello',"1111111111111");
			//       // this.right_data.push('23_23_'+this.textarea) ;

			//       //给医生发送邮箱

			//       const params = new URLSearchParams();
			//       params.append('uid', sessionStorage.getItem('uid'));
			//       params.append('did', this.did);
			//       params.append('content', this.did);

			//       axios.post(this.apiUrl+'/api/user/setEmail',params)
			//       .then((response) => {  
			//       	console.log(response.data.data);

			//       	//this.info = response.data.data;  

			//       }) 
			//       .catch(function(error) {
			//         console.log(error);
			//       });

			//   }
			// });

			//     console.log('aa');

			//     // 开始计时
			//     let time = 0
			//     this.timeInterval = setInterval(() => {
			//       time++
			//       let m = Math.floor(time/60)
			//       if (m >= 1) {
			//         this.isEnd = true
			//       }
			//       let s = time%60
			//       m = this.toDuble(m)
			//       s = this.toDuble(s)
			//       this.duration = m + ':' + s

			//     }, 1000)
			//     this.$nextTick(()=>{
			//       this.$refs['scroll'].wrap.scrollTop = this.$refs['scroll'].wrap.scrollHeight;
			//     })
			//   },
			//   beforeDestroy() {
			//     clearInterval(this.timeInterval);
			//  // 页面离开时断开连接,清除定时器
			//  this.disconnect();
			//  clearInterval(this.timer);
			//   }, 
			//   mounted(){
			// this.initWebSocket();

		//},

		created() {





			//    var file = "http://doctor.com//qrcode/16514544434483999.png";
			// 	  var reader = new FileReader()
			//    reader.readAsDataURL(file)


			//    alert(2323);


			//this.enterSearch();
			//this.pay_img = 'http://doctor.com//qrcode/16514544434483999.png';
			//this.$refs.ChatRoomPay.open();

			var query = this.$route.query;
			this.did = query.did;


			//-------------------------------------------------------------------------

			//初始化了聊天信息 

			//this.id = query.id; 
			const params = new URLSearchParams();
			params.append('uid', sessionStorage.getItem('uid'));

			axios.post(this.apiUrl + '/api/user/getInfo', params)
				.then((response) => {
					this.id_img = this.apiUrl + response.data.data.pic;
					this.chatTemplates = response.data.data.caseList;
					this.chatImgTemplates = response.data.data.caseImgList;

				})
				.catch(function(error) {
					console.log(error);
				});
			const params2 = new URLSearchParams();
			params2.append('uid', this.did);

			axios.post(this.apiUrl + '/api/user/getInfo', params2)
				.then((response) => {
					this.did_img = this.apiUrl + response.data.data.pic;


				})
				.catch(function(error) {
					console.log(error);
				});



			const params3 = new URLSearchParams();

			params3.append('uid', this.did);
			params3.append('uuid', sessionStorage.getItem('uid'));

			axios.post(this.apiUrl + '/api/user/getInfo', params3)
				.then((response) => {
					this.info = response.data.data;

				})
				.catch(function(error) {
					console.log(error);
				});


			// const params4 = new URLSearchParams();

			// params4.append('uid', this.did); 

			// axios.post(this.apiUrl+'/api/chatroom/getTextTemplate',params3)
			// .then((response) => {   
			// 	  this.chatTemplates = response.data.data;     

			// }) 
			// .catch(function(error) {
			//   console.log(error);
			// }); 



			//this.textarea ="223";
			//-------------------------------------------------------------------------



			//一定要移除旧的message监听，否则会出现重复监听的状况
			socket.removeListener('hello');
			//这是移除所有监听
			// socket.removeAllListeners();
			socket.on('hello', (data) => {

				//聊天室病例详情





				// console.log(data)
				//this.left_data.push(this.text++);
				//this.reft_data.push(this.text++);
				// this.mess = data;
				// 发送人id_接收人id_内容
				const mes = data.split('_');
				// console.log("///////////////") ;
				// console.log(mes[10]);
				// console.log("///////////////") ;








				//住id
				const uid = sessionStorage.getItem('uid');
				//对方id
				const did = query.did;
				const goMessAll = {};
				const g = mes[4];

				//接收的人是我     1文字  2普通图片    3带连接病例图片  4撤回
				if (mes[1] == uid) {




					if (mes[3] == '3') {
						this.goMessAll = {
							local: 0,
							show_type: 3,
							mess: mes[2],
							ids: g,
						};
						this.all_data.push(this.goMessAll);
					}

					if (mes[3] == '2') {
						this.goMessAll = {
							local: 0,
							show_type: 2,
							mess: mes[2],
							ids: g,
						};
						this.all_data.push(this.goMessAll);
					}


					if (mes[3] == '1') {
						this.goMessAll = {
							local: 0,
							show_type: 1,
							mess: mes[2],
							ids: g,
						};
						this.all_data.push(this.goMessAll);
					}



					//撤回
					if (mes[3] == '4') {
						$('#' + mes[2]).hide();
					}

				}


				// //发送的人是我  1文字  2普通图片    3带连接病例图片  4撤回
				if (mes[0] == uid) {

					if (mes[3] == '3') {
						this.goMessAll = {
							local: 1,
							show_type: 3,
							mess: mes[2],
							message: 'Baz',
							ids: g,
						};
						this.all_data.push(this.goMessAll);
					}

					if (mes[3] == '2') {
						this.goMessAll = {
							local: 1,
							show_type: 2,
							mess: mes[2],
							message: 'Baz',
							ids: g,
						};
						this.all_data.push(this.goMessAll);
					}

					if (mes[3] == '1') {
						this.goMessAll = {
							local: 1,
							show_type: 1,
							mess: mes[2],
							message: 'Baz',
							ids: g,
						};
						this.all_data.push(this.goMessAll);
					}


				}



				// //接收的人是我  3带连接病例图片 2普通图片  1文字
				// if(mes[1] == uid){ 


				// 	//撤回消息
				// 	if(mes[3] == '4'){
				// 		alert(1111)
				// 		$("#"+mes[2]).hidde();
				// 	}

				// 	//支付
				// 	if(mes[3] == '3'){
				// 		$(".newsList").append("<div class='news left' style='width:100%;text-align:left'> "
				// 		+"<img class='news_avatar' style='float:left'  width='50' src='"+this.id_img+"'  /> "
				// 		+" <div class='news_content' style='float:left;margin-left:10px;'>  <span class='time'  >今天21:33</span>  <br/>  "
				// 		+"<span class='text case_span' url='"+this.apiUrl+"'  did='"+uid+"'  uid='"+this.did+"' > <img src='"+mes[2]+"' width=100 ></span></div></div>"
				// 		+"<div style='clear:both;'></div>"
				// 		); 
				// 	}  

				// 	if(mes[3] == '2'){

				// 			alert(22)
				// 		$(".newsList").append("<div id='"+mes[4]+"' class='news left' style='width:100%;text-align:left'> "
				// 		+"<img class='news_avatar' style='float:left'  width='50' src='"+this.id_img+"'  /> "
				// 		+" <div class='news_content' style='float:left;margin-left:10px;'>  <span class='time'  >今天21:33</span>  <br/>  "
				// 		+"<span class='text rightClick'  > <img src='"+mes[2]+"' width=100 ></span></div></div>"
				// 		+"<div style='clear:both;'></div>"
				// 		);
				// 	}


				// 	if(mes[3] == '1'){ 
				// 		$(".newsList").append("<br/><div  id='"+mes[4]+"' class='news left' style='width:100%;text-align:left' > "
				// 		+"<img class='news_avatar' style='float:left'  width='50' src='"+this.did_img+"' />  "
				// 		+"<div class='news_content' style='float:left;margin-left:10px;' >  <span class='time'>今天21:33</span>   <br/><br/>  "
				// 		+" <span class='text' style='color:white;background:green;padding:10px 20px'  > "+mes[2]+" </span></div></div>"
				// 		+"<div style='clear:both;'></div>"
				// 		);
				// 	}

				// 	console.log(mes[0] +"=left=" +uid);
				// 	//展示左边
				// 	//this.left_data.push(mes[2]) ;
				// }
				// //发送的人是我	  3带连接病例图片 2普通图片  1文字
				// if(mes[0] == uid){

				// 	//2  是图片
				// 	if(mes[3] == '3'){
				// 		$(".newsList").append("<div class='news right' style='width:100%;text-align:right'> "
				// 		+"<img class='news_avatar'  style='float: right;margin-left:10px; ' width='50' src='"+this.id_img+"'  /> "
				// 		+" <div class='news_content'  style='float: right;' >  <span class='time'>今天21:33</span> <br/> "
				// 		+"<span class='text case_span rightClick'  url='"+this.apiUrl+"'  did='"+this.did+"'  uid='"+uid+"'  > <img   onclick='asd()'  class='roomCase' src='"+mes[2]+"' width=100 ></span></div></div>" 
				// 		+"<div style='clear:both;'></div>"
				// 		);

				// 	}  

				// 	if(mes[3] == '2'){
				// 		$(".newsList").append("<div  id='"+mes[4]+"' class='news right' style='width:100%;text-align:right'> "
				// 		+"<img class='news_avatar'  style='float: right;margin-left:10px; ' width='50' src='"+this.id_img+"'  /> "
				// 		+" <div class='news_content'  style='float: right;' >  <span class='time'>今天21:33</span> <br/> "
				// 		+"<span class='text rightClick'    > <img src='"+mes[2]+"' width=100 ></span></div></div>"
				// 		+"<div style='clear:both;'></div>"
				// 		);
				// 	}

				// 	if(mes[3] == '1'){
				// 		$(".newsList").append("<br/><div class='news right' style='width:100%;text-align:right'> "
				// 		+"<img class='news_avatar'  style='float: right;margin-left:10px; ' width='50'  src='"+this.id_img+"'  /> "
				// 		+" <div class='news_content ' style='float: right;' >  <span class='time'>今天21:33</span>  <br/> <br>  "
				// 		+"<span class='text rightClick' style='color:white;background:green;padding:10px 20px' > "+mes[2]+" </span></div></div>"
				// 		+"<div style='clear:both;'></div>"
				// 		);
				// 	}

				//  	//展示右边
				// 	//this.right_data.push(mes[2]);
				// }
				//情况 输入框 聊天啊信息
				this.textarea = "";
			});
		},

	}
</script>

<style scoped lang="scss">
	.reght_css {
		background-color: #009688 !important;
		color: white !important;
	}

	.bubble::before {

		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 8px 8px 0;
		border-color: transparent #F7F7F7 transparent transparent;
		content: '\A';
		position: absolute;
		left: 0px;
		top: 25px;
		border-right-color: red;

		z-index: 999999;
	}


	.chatWrap {
		width: 1200px;
		margin: 32px auto;
		display: flex;

		.chatMain {
			width: 1200px;

			.head {
				height: 162px;
				width: 100%;
				padding: 8px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				background-color: #81D3F7;

				.avatar {
					width: 128px;
					height: 146px;
					border-radius: 6px;
					overflow: hidden;
					margin-right: 25px;
				}

				.info {
					width: 835px;
					font-size: 12px;
					font-weight: 400;
					color: #666666;
					line-height: 17px;

					.el-col {}

					.nick {
						font-size: 16px;
						font-weight: 600;
						color: #333333;
						line-height: 22px;
						margin-right: 12px;
					}

					.name {
						margin-right: 29px;
					}

					.id {
						margin-right: 31px;
					}

					.lastTime {}

					.rate_item {
						margin: 8px 0;

						span {
							font-size: 12px;
						}

						.el-progress {
							display: inline-block;
						}
					}

					.info_detail {
						margin: 8px 0;
					}
				}

				.videoCall {
					position: relative;
					width: 215px;
					height: 100%;
					text-align: right;

					.video {
						position: absolute;
						right: 9px;
						top: 4px;
						width: 164px;
						height: 96px;
						background: #FFFFFF;
						border-radius: 10px;
					}

					.nav {
						width: 215px;
						height: 20px;
						font-size: 14px;
						font-weight: 500;
						color: #666666;
						line-height: 20px;
						position: absolute;
						bottom: 12px;
						right: 9px;

						.nav_item {
							cursor: pointer;
						}

						.el-divider--vertical {
							height: 10px;
							width: 2px;
							margin: 0 5px;
							background-color: #999;
							vertical-align: unset;
						}
					}
				}
			}

			.appendHead {
				width: 1200px;
				height: 82px;
				padding: 4px 8px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				background-color: #81D3F7;
				margin-top: 1px;

				.appendAvatar {
					width: 64px;
					height: 75px;
					border-radius: 6px;
					overflow: hidden;
					margin-right: 18px;
				}

				.appendInfo {
					width: 1100px;
					font-size: 12px;
					font-weight: 400;
					color: #666666;
					line-height: 22px;

					&>div {
						width: 100%;
					}
				}
			}

			.warning {
				width: 100%;
				height: 29px;
				background: #FEEDD9;
				padding-left: 46px;
				font-size: 12px;
				font-weight: 600;
				color: #0070FF;
				line-height: 29px;
				box-sizing: border-box;
			}

			.newsWrap {
				height: 570px;
				background-color: #fff;
				/*padding: 40px;*/
				box-sizing: border-box;
				border: 1px solid rgba(151, 151, 151, 0.3);
				position: relative;

				.newsList {
					min-height: 100%;
					width: 1120px;
					margin: 40px auto;

					.news {
						display: flex;
						margin-bottom: 30px;
						position: relative;

						.el-image {
							width: 42px;
							height: 47px;
						}

						.news_avatar_append {
							position: absolute;
							right: -24px;
							width: 24px;
							height: 24px;
							bottom: 4px;
						}

						.news_content {

							display: flex;
							flex-direction: column;

							.time {
								font-size: 12px;
								font-weight: 600;
								color: #999999;
								line-height: 17px;
							}

							.text {
								max-width: 500px;
								background: #F2F5FA;
								padding: 7px 15px;
								font-size: 14px;
								font-weight: 400;
								color: #333330;
								line-height: 20px;
								position: relative;

								&:before {
									content: '';
									width: 0;
									height: 0;
									border: 7px solid transparent;
									position: absolute;
									left: -14px;
									top: 50%;
									transform: translateY(-50%);
								}
							}
						}

					}

					.left {


						.el-image {
							margin-right: 15px;
						}

						.text:before {
							border-right-color: #F2F5FA !important;
							left: -14px !important;
						}
					}

					.right {
						flex-direction: row-reverse;
						text-align: right;

						.el-image {
							margin-left: 15px;
						}

						.text:before {
							border-left-color: #009688 !important;
							left: 100% !important;
						}
					}
				}

				.newEnd {
					padding-bottom: 100px;
				}

				.beforeEnd {
					position: relative;
					bottom: 20px;
					padding-left: 410px;

					p {
						font-size: 12px;
						line-height: 17px;
					}

					.navBtn {
						padding: 4px 0;
						// background-color: $green;
					}
				}
			}

			.send_tools_bar {
				width: 1200px;
				height: auto;
				background: #F0F0F0;
				display: flex;
				justify-content: space-between;
				font-size: 20px;
				line-height: 50px;

				.left_tools {
					/*width: 360px;*/
					display: flex;
					align-items: center;
					height: 50px;

					i {
						margin-left: 40px;
					}

					.icon8 {
						display: inline-block;
						width: 24px;
						height: 26px;
						margin-left: 40px;
					}

					.left_tools_item {
						float: left;
						position: relative;

						.chatTemplate {
							position: absolute;
							width: 522px;
							height: 155px;
							background: #F4FEFF;
							border: 1px solid #76D8DF;
							bottom: 55px;
							left: 10px;

							.template_item {
								display: flex;
								padding: 0 14px;
								justify-content: space-between;
							}

							&:before {
								content: '';
								width: 0;
								height: 0;
								border: 10px solid;
								border-color: #76D8DF transparent transparent transparent;
								position: absolute;
								bottom: -21px;
								left: 30px;
							}

							&:after {
								content: '';
								width: 0;
								height: 0;
								border: 10px solid;
								border-color: #F4FEFF transparent transparent transparent;
								position: absolute;
								bottom: -19px;
								left: 30px;
							}
						}
					}
				}

				.right_tools {
					margin-right: 40px;
					font-size: 24px;
				}
			}

			.input_wrap {
				position: relative;

				.send {
					position: absolute;
					bottom: 0px;
					right: 0px;
					background-color: #fff;
					text-align: right;
					padding: 10px 30px 30px;
					width: calc(100% - 40px);
					margin: 0 30px 1px 10px;
					box-sizing: border-box;

					small {
						color: #999999;
					}
				}
			}
		}

		.chatSide {
			.side_top {
				width: 100px;

				.timeCount {
					margin-top: 20px;
				}
			}

			.side_middle {
				margin-top: 190px;
			}
		}

	}

	.info /deep/ .el-progress-bar__inner {
		background-color: $green !important;
	}

	.info /deep/ .el-progress-bar {
		width: 150px;
	}

	.info /deep/ .el-progress__text {
		font-size: 12px !important;
	}

	.input_wrap /deep/ .el-textarea__inner {
		font-size: 14px;
		padding-bottom: 70px;
	}

	.input_wrap /deep/ .el-textarea__inner:focus {
		border-color: #DCDFE6;
	}

	.input_wrap /deep/ .el-button--small,
	.el-button--small.is-round {
		width: 78px;
		height: 32px;
		background-color: #fff !important;
		color: $green;
		border-radius: 16px !important;
		margin-left: 15px;
	}

	.side_top /deep/ .el-button {
		background-color: transparent !important;
		color: #666;
		border-color: #D9D9D9 !important;
		width: 82px;
		height: 35px;
		margin-left: 18px;
	}

	.side_middle /deep/ .el-button {
		background-color: transparent !important;
		color: #666;
		border-color: #D9D9D9 #D9D9D9 #D9D9D9 transparent !important;
		width: 59px;
		height: 28px;
		font-size: 12px;
	}

	.template_item /deep/ .el-input {
		width: 78%;
	}

	.template_item /deep/ .el-button {
		color: $green;
	}

	/deep/ .el-scrollbar__wrap {
		overflow-x: hidden !important;
	}
</style>
